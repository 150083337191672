/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import CountUp from "react-countup";
import ManualTab from "./manualtab";
import AutomatedTab from "./automatedtab";
import click from "../../assets/sound/click.mp3";
import counting from "../../assets/sound/counting.MP3";
import lose from "../../assets/sound/lose.MP3";
import win from "../../assets/sound/win.wav";
import fairnessicon from "../../assets/images/game/fairnessicon.svg";
import livestats from "../../assets/images/game/live.png";
import "./limbo.css";
import { Modal } from "react-bootstrap";
import FainessPopup from "./fainesspopup";
import LiveStats from "./livestats";
// import { getCookie } from "../../utils/cookies";
import { limboInstance } from "../../config/axios";
// import { socket } from "../../config/socket";
import { useAuthStore } from "../../store/auth";
let timeoutId;
let newNumberOfAutoBets = 0;
let isCancel = false;
let isInfinite = false;
let winIncreaseValue;
let lossIncreaseValue;
let stopWinWallet = 0;
let stopLossWallet = 0;
let stopOnWinValue = 0;
let stopOnLossValue = 0;
// let updateWallet;
const Limbo = () => {
  const [multiplier, setMultiplier] = useState(48.5);
  const [targetMuliplier, setTargetMuliplier] = useState(2);
  const [multiplierError, setMultiplierError] = useState("");
  const [profitOnwin, setProfitOnwin] = useState(0.2);
  const [volume, setVolume] = useState(true);
  const [betAmount, setBetAmount] = useState(0.1);
  const [clientSeed, setClientSeed] = useState(0);
  const [serverSeed, setServerSeed] = useState(0);
  const [result, setResult] = useState();
  const [nonce, setNonce] = useState(0);
  const [showHistory, setShowHistory] = useState("");
  const [pastPlayedGames, setPastPlayedGames] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const [allSeeds, setAllSeeds] = useState();
  const [fairnessResult, setFairnessResult] = useState("");
  const [fairness, setFairness] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [stats, setStats] = useState(false);
  const [key, setKey] = useState("manual");
  const [testMode, setTestMode] = useState(
    localStorage.getItem("testmode") === "true"
  );
  const { user } = useAuthStore((state) => state);
  const [scoreColor, setScoreColor] = useState("whitecolor");
  useEffect(() => {
    setShowHistory("");
  }, []);

  useEffect(() => {
    const availableChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 20; i++) {
      randomString +=
        availableChars[Math.floor(Math.random() * availableChars.length)];
    }
    setClientSeed(randomString);
  }, []);

  const handleClose = () => {
    setFairness(false);
    setFairnessResult("");
  };

  const handleShow = () => {
    setFairnessResult("");
    setFairness(true);
  };

  const handleTestMode = () => {
    const msg = !testMode ? "enabled" : "disabled";
    if (msg === "disabled") {
      window.parent.postMessage(
        { type: "testModeDisable", msg: msg, testMode: testMode },
        "*"
      );
    } else {
      window.parent.postMessage(
        { type: "testMode", msg: msg, testMode: testMode },
        "*"
      );
    }
    setTestMode(!testMode);
    setBetAmount(0.1);
    setAutoBetAmount(0.1);
    localStorage.setItem("testmode", !testMode);
  };

  const handlestatsClose = () => {
    setStats(false);
  };

  const handlestatsShow = async () => {
    setStats(true);
  };

  const getStatsData = async () => {
    await limboInstance()
      .get("/getLiveStats")
      .then((response) => {
        setStatsData([response?.data?.bettingData, ...statsData]);
      });
  };

  useEffect(() => {
    if (stats) {
      getStatsData();
    }
  }, [stats]);

  const betClickHandle = async () => {
    const reqData = {
      params: {
        clientSeed: clientSeed,
        targetMultiplier: targetMuliplier,
        betAmount: betAmount,
        profitOnwin: profitOnwin,
        winChance: multiplier,
      },
    };

    try {
      const response = await limboInstance().get(`/result`, reqData);
      if (response?.status === 200) {
        const { result } = response?.data;
        parseFloat(result) >= parseFloat(targetMuliplier)
          ? setScoreColor("greenNumb")
          : setScoreColor("redNumb");
        setResult(response?.data?.result ? response?.data?.result : "");
        setServerSeed(
          response?.data?.serverSeed ? response?.data?.serverSeed : ""
        );
        setNonce(response?.data?.nonce ? response?.data?.nonce : "");
        setPastPlayedGames((prevArr) => [
          {
            result: result,
            isCheck:
              parseFloat(result) >= parseFloat(targetMuliplier)
                ? "win"
                : "loss",
          },
          ...prevArr,
        ]);
      }
    } catch (error) {
      const msg = error?.response?.data?.message;
      window.parent.postMessage({ type: "errorMessageShow", msg: msg }, "*");
    }
  };

  const randomNumberGenerater = () => {
    let num = Math.random() * 4 + Math.random();
    return num;
  };

  const amountUserAndTestCaseCheck = () => {
    if (!testMode && !user) {
      window.parent.postMessage("loginRequest", "*");
      return false;
    }
    if (!testMode) {
      if (user && user.wallet < betAmount) {
        window.parent.postMessage("walletUpdate", "*");
        return false;
      }
    }
    setScoreColor("whiteColor");
    const modifyBetAmount = betAmount * 1;
    setBetAmount(modifyBetAmount);
    if (!testMode && modifyBetAmount < 0.1) {
      seterrorMessage("Bet amount should be equal or greater than 0.1");
      setTimeout(() => {
        seterrorMessage("");
      }, [3000]);
      return false;
    }

    if (
      !testMode &&
      (parseFloat(betAmount) === 0 ||
        betAmount === "" ||
        betAmount === null ||
        betAmount === undefined ||
        isNaN(betAmount))
    ) {
      seterrorMessage("Bet amount should be greater than zero");
      setTimeout(() => {
        seterrorMessage("");
      }, [3000]);
      return false;
    }
    if (multiplierError !== "" || multiplierError.length > 0) {
      return false;
    }
    setIsDisabled(true);
    playAudio("click");
    if (testMode) {
      const num = randomNumberGenerater();
      if (num > multiplier) {
        setResult(multiplier);
      } else {
        setResult(num);
      }
      playAudio(
        parseFloat(num) >= parseFloat(targetMuliplier) ? "win" : "lose"
      );
      parseFloat(num) >= parseFloat(targetMuliplier)
        ? setScoreColor("greenNumb")
        : setScoreColor("redNumb");
      setIsDisabled(false);
      return false;
    }
    return true;
  };

  const handleBet = async () => {
    const isCheck = amountUserAndTestCaseCheck();
    if (!isCheck) {
      return;
    }
    setIsDisabled(true);
    playAudio("click");
    setScoreColor("whiteColor");
    await betClickHandle();
    setIsDisabled(false);
  };

  const handleSetNonce = async (e) => {
    setNonce(e?.target?.value);
  };

  const handleBetAmount = (e) => {
    let newStr = e?.target?.value;
    let number = Number(newStr);
    if (Number.isNaN(number)) return;
    setBetAmount(newStr);
    setProfitOnwin(
      (parseFloat(newStr * targetMuliplier) - parseFloat(newStr)).toFixed(2)
    );
  };

  const playAudio = (type) => {
    let sound = localStorage.getItem("sound");
    if (sound === "true") {
      if (type) {
        const audioEl = document.getElementsByClassName(`audio-${type}`)[0];
        if (audioEl) {
          audioEl.play();
        }
      }
    }
  };

  const handleMultiplier = (e) => {
    let ele = e.target.value;
    let el = +ele;
    if (key === "manual") {
      if (el === "" || el === 0) {
        el = 2;
        setTargetMuliplier(el);
        setMultiplier((97 / el).toFixed(2));
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * betAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
      } else if (el < 1.01) {
        el = 1.01;

        setTargetMuliplier(el);
        setMultiplierError(`minimum multiplier value is 1.01`);
        setTimeout(() => {
          setMultiplierError(``);
        }, 1000);
        setMultiplier((97 / el).toFixed(2));
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * betAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
      } else if (el > 1000) {
        el = 1000;
        setTargetMuliplier(el);
        setMultiplierError(`maximum multiplier value is "1000"`);
        setTimeout(() => {
          setMultiplierError(``);
        }, 1000);
        setMultiplier((97 / el).toFixed(2));
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * betAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
      } else {
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * betAmount).toFixed(3));
        } else {
          setProfitOnwin(0.0);
        }
        setMultiplier((97 / el).toFixed(2));
        setMultiplierError(``);
      }
    }
    if (key === "automated") {
      if (el === "" || el === 0) {
        el = 2;
        setTargetMuliplier(el);
        setMultiplier((97 / el).toFixed(2));
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * autoBetAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
      } else if (el < 1.01) {
        el = 1.01;
        setTargetMuliplier(el);
        setMultiplierError(`minimum multiplier value is 1.01`);
        setTimeout(() => {
          setMultiplierError(``);
        }, 1000);
        setTimeout(() => {
          setMultiplierError(``);
        }, 1000);
        setMultiplier((97 / el).toFixed(2));
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * autoBetAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
      } else if (el > 1000) {
        el = 1000;
        setTargetMuliplier(el);
        setMultiplierError(`maximum multiplier value is "1000"`);
        setTimeout(() => {
          setMultiplierError(``);
        }, 1000);
        setMultiplier((97 / el).toFixed(2));
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * autoBetAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
      } else {
        if (
          Number.isFinite(el) &&
          el > 0 &&
          (el % 1 !== 0 || el === Math.floor(el))
        ) {
          setProfitOnwin(parseFloat(el * autoBetAmount).toFixed(2));
        } else {
          setProfitOnwin(0.0);
        }
        setMultiplier((97 / el).toFixed(2));
        setMultiplierError(``);
      }
    }
  };

  const handelTargetMultiplier = (e) => {
    let el = e?.target?.value;
    if (el.indexOf("-") !== -1) {
      el = el.replace("-", "");
    }
    let ele = +el;
    if (key === "manual") {
      if (
        Number.isFinite(ele) &&
        ele > 0 &&
        (ele % 1 !== 0 || ele === Math.floor(ele))
      ) {
        setProfitOnwin((parseFloat(el * betAmount) - betAmount).toFixed(2));
      } else {
        setProfitOnwin(0.0);
      }
    }
    if (key === "automated") {
      if (
        Number.isFinite(ele) &&
        ele > 0 &&
        (ele % 1 !== 0 || ele === Math.floor(ele))
      ) {
        setProfitOnwin(
          (parseFloat(el * autoBetAmount) - autoBetAmount).toFixed(2)
        );
      } else {
        setProfitOnwin(0.0);
      }
    }

    setMultiplier((97 / el).toFixed(2));
    setTargetMuliplier(parseFloat(el));
  };

  const handleBetAmountUpdate = (value) => {
    if (user) {
      playAudio("click");
      if (value === "Max") {
        setBetAmount(1000);
        setProfitOnwin(parseFloat(1000 * targetMuliplier).toFixed(2));
        return;
      }

      if (value === "2x") {
        const betValueAmount = parseFloat(betAmount * 2);
        // walletValue
        if (betValueAmount <= 1000) {
          setBetAmount(parseFloat(betAmount * 2).toFixed(2));
          setProfitOnwin(
            parseFloat(parseFloat(betAmount) * 2 * targetMuliplier).toFixed(2)
          );
        }
        return;
      }

      if (value === "1/2") {
        if (parseFloat(betAmount / 2) >= 0.1) {
          setBetAmount(parseFloat(betAmount / 2).toFixed(2));
          setProfitOnwin(
            (parseFloat(betAmount / 2).toFixed(2) * targetMuliplier).toFixed(2)
          );
        } else {
          setBetAmount(0.1);
          setProfitOnwin((0.1 * targetMuliplier).toFixed(2));
        }
        return;
      }
    } else {
      setIsDisabled(true);
    }
  };

  const fairnessCalculate = async () => {
    if (serverSeed?.length > 0) {
      const reqData = {
        params: {
          clientSeed: clientSeed,
          nonce: nonce,
          serverSeed: serverSeed,
        },
      };
      await limboInstance()
        .get(`/verify`, reqData)
        .then((response) => {
          let responseResult = response?.data;
          if (
            String(parseFloat(responseResult).toFixed(2)) ===
            String(parseFloat(result).toFixed(2))
          ) {
            setFairnessResult(response?.data);
          }
        });
    }
  };

  useEffect(() => {
    setAllSeeds({
      clientSeed: clientSeed,
      serverSeed: serverSeed,
      nonce: nonce,
    });
  }, [nonce, serverSeed, clientSeed]);

  useEffect(() => {
    async function getFairness() {
      await fairnessCalculate();
    }
    getFairness();
    // eslint-disable-next-line
  }, [showHistory]);
  /* Autobet code starts here */
  const [numberOfAutoBets, setNumberOfAutoBets] = useState(0);
  const [autoBetAmount, setAutoBetAmount] = useState(0.1);
  const [activeAutoBets, setActiveAutoBets] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [autoWinIncrease, setAutoWinIncrease] = useState(0);
  const [autoLossIncrease, setAutoLossIncrease] = useState(0);
  const [stopOnWin, setStopOnWin] = useState(0);
  const [stopOnLoss, setStopOnLoss] = useState(0);
  const [stopAutoBet, setStopAutoBet] = useState(false);
  const [isFiniteCase, setIsFiniteCase] = useState(false);

  useEffect(() => {
    if (stopOnWin > 0) {
      setStopAutoBet(false);
    }
    // eslint-disable-next-line
  }, [stopOnWin, stopAutoBet]);

  useEffect(() => {
    if (stopOnLoss > 0) {
      setStopAutoBet(false);
    }
    // eslint-disable-next-line
  }, [stopOnLoss, stopAutoBet]);

  const handleCancel = () => {
    isCancel = true;
    isInfinite = false;
    setIsFiniteCase(false);
    setIsDisabled(false);
    setStopOnWin(0);
    setStopOnLoss(0);
    stopLossWallet = 0;
    stopWinWallet = 0;
    stopOnLossValue = 0;
    stopOnWinValue = 0;
    // setNumberOfAutoBets(0)
  };

  const testModePlayGame = () => {
    if (newNumberOfAutoBets > 0) {
      newNumberOfAutoBets--;
    }
    const num = randomNumberGenerater();
    if (num > multiplier) {
      setResult(multiplier);
    } else {
      setResult(num);
    }
    playAudio(parseFloat(num) >= parseFloat(targetMuliplier) ? "win" : "lose");
    parseFloat(num) >= parseFloat(targetMuliplier)
      ? setScoreColor("greenNumb")
      : setScoreColor("redNumb");
    if (!isCancel)
      if (newNumberOfAutoBets > 0 || isInfinite) {
        setTimeout(async () => {
          return autoBetApiCall();
        }, 1500);
      }
    return false;
  };

  const autoBetApiCall = async (reqDataAuto) => {
    if (isCancel) {
      return;
    }
    if (parseInt(newNumberOfAutoBets) > 0 || isInfinite) {
      setIsDisabled(true);
    }

    if (testMode) {
      const isCheck = testModePlayGame();
      if (!isCheck) {
        return;
      }
    }
    if (newNumberOfAutoBets > 0) {
      newNumberOfAutoBets--;
    }
    try {
      const response = await limboInstance().get(`/result`, reqDataAuto);
      if (response.status === 200) {
        const { result, tokenValue } = response?.data;
        setResult(response?.data?.result ? response?.data?.result : "");
        setPastPlayedGames((prevArr) => [
          {
            result: result,
            isCheck:
              parseFloat(result) >= parseFloat(targetMuliplier)
                ? "win"
                : "loss",
          },
          ...prevArr,
        ]);
        parseFloat(result) >= parseFloat(targetMuliplier)
          ? setScoreColor("greenNumb")
          : setScoreColor("redNumb");
        setServerSeed(
          response?.data?.serverSeed ? response?.data?.serverSeed : ""
        );
        setNonce(response?.data?.nonce ? response?.data?.nonce : "");
        let isCheckWinOrLoss =
          parseFloat(result) >= parseFloat(targetMuliplier) ? true : false;
        if (isCheckWinOrLoss) {
          if (autoWinIncrease > 0) {
            lossIncreaseValue = false;
            winIncreaseValue =
              parseFloat(reqDataAuto?.params?.betAmount) +
              parseInt(autoWinIncrease);
            setAutoBetAmount(
              (prev) => parseFloat(prev) + parseInt(autoWinIncrease)
            );
          }
          if (Number(stopOnWinValue) > 0 && Number(stopWinWallet) > 0) {
            if (tokenValue - Number(stopWinWallet) >= Number(stopOnWinValue)) {
              handleCancel();
            }
          }
        }

        if (!isCheckWinOrLoss) {
          if (autoLossIncrease > 0) {
            winIncreaseValue = false;
            lossIncreaseValue =
              parseFloat(reqDataAuto?.params?.betAmount) +
              parseInt(autoLossIncrease);
            setAutoBetAmount(
              (prev) => parseFloat(prev) + parseInt(autoLossIncrease)
            );
          }
          if (Number(stopOnLossValue) > 0 && Number(stopLossWallet) > 0) {
            if (
              Number(stopLossWallet) - Number(tokenValue) >=
              Number(stopOnLossValue)
            ) {
              handleCancel();
            }
          }
        }
      }

      if (!isCancel)
        if (newNumberOfAutoBets > 0 || isInfinite) {
          setTimeout(async () => {
            const updatedBetAmount = winIncreaseValue
              ? winIncreaseValue
              : lossIncreaseValue;
            const payload = {
              params: {
                clientSeed: clientSeed,
                betAmount: updatedBetAmount ? updatedBetAmount : autoBetAmount,
                targetMultiplier: targetMuliplier,
                winChance: multiplier,
                nonce: nonce,
              },
            };

            return await autoBetApiCall(payload);
          }, 1500);
        }
    } catch (error) {
      const msg = error?.response?.data?.message;
      window.parent.postMessage({ type: "errorMessageShow", msg: msg }, "*");
      newNumberOfAutoBets = 0;
      setIsDisabled(false);
      isCancel = true;
      isInfinite = false;
      setIsFiniteCase(false);
      setStopOnWin(0);
      setStopOnLoss(0);
      stopLossWallet = 0.0;
      stopWinWallet = 0.0;
      stopOnLossValue = 0.0;
      stopOnWinValue = 0.0;
    }
  };

  const checkNaNVariable = () => {
    if (isNaN(autoWinIncrease)) {
      setAutoWinIncrease(0);
    }
    if (isNaN(autoLossIncrease)) {
      setAutoLossIncrease(0);
    }
  };

  const checkTestAndValidation = () => {
    if (!testMode && !user) {
      window.parent.postMessage("loginRequest", "*");
      return false;
    }
    if (!testMode && (parseFloat(autoBetAmount) < 0.1 || autoBetAmount < 0.1)) {
      seterrorMessage("Bet amount should be equal or greater than 0.1");
      setTimeout(() => {
        seterrorMessage("");
      }, [3000]);
      return false;
    }
    if (
      !testMode &&
      (parseFloat(autoBetAmount) === 0 ||
        autoBetAmount === "" ||
        autoBetAmount === null ||
        autoBetAmount === undefined ||
        isNaN(autoBetAmount))
    ) {
      seterrorMessage("Auto Bet Amount should be greater than zero");
      setTimeout(() => {
        seterrorMessage("");
      }, [3000]);
      return false;
    }
    return true;
  };

  const handleAutobetClick = async () => {
    checkNaNVariable();
    const isCheck = checkTestAndValidation();
    if (!isCheck) {
      return;
    }
    playAudio("click");
    if (newNumberOfAutoBets === 0 || isNaN(newNumberOfAutoBets)) {
      isInfinite = true;
      setIsFiniteCase(true);
      setIsDisabled(true);
    }

    const payload = {
      params: {
        clientSeed: clientSeed,
        betAmount: autoBetAmount,
        targetMultiplier: targetMuliplier,
        winChance: multiplier,
        nonce: nonce,
      },
    };
    isCancel = false;
    await autoBetApiCall(payload);
  };

  const handleAutoBetAmount = (e) => {
    let newStr = e?.target?.value;
    let number = Number(newStr);
    if (Number.isNaN(number)) return;
    setAutoBetAmount(newStr);
    setProfitOnwin(
      (parseFloat(newStr * targetMuliplier) - parseFloat(newStr)).toFixed(2)
    );
  };

  const handleAutoBetAmountUpdate = (value) => {
    if (user) {
      playAudio("click");
      if (value === "Max") {
        setAutoBetAmount(1000);
        setProfitOnwin(parseFloat(1000 * targetMuliplier).toFixed(2));
        return;
      }
      if (value === "2x") {
        if (parseFloat(autoBetAmount) * 2 <= 1000) {
          setAutoBetAmount(parseFloat(autoBetAmount * 2).toFixed(2));
          setProfitOnwin(
            (parseFloat(autoBetAmount) * 2 * targetMuliplier).toFixed(2)
          );
          return;
        }
      }
      if (value === "1/2") {
        if (parseFloat(autoBetAmount / 2) >= 0.1) {
          setAutoBetAmount(parseFloat(autoBetAmount / 2).toFixed(2));
          setProfitOnwin(
            (parseFloat(autoBetAmount / 2) * targetMuliplier).toFixed(2)
          );
        } else {
          setAutoBetAmount(0.1);
          setProfitOnwin((0.1 * targetMuliplier).toFixed(2));
        }
      }
    } else {
      setIsDisabled(true);
    }
  };

  const handleNumberOfBets = (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    newNumberOfAutoBets = parseInt(newStr);
    setNumberOfAutoBets(parseInt(newStr));
  };

  const handleAutoWinIncrease = (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    setAutoWinIncrease(parseFloat(newStr));
  };

  const handleAutoLossIncrease = (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    setAutoLossIncrease(parseFloat(newStr));
  };

  const resetWinIncrease = () => {
    setAutoWinIncrease(0);
  };

  const resetLossIncrease = () => {
    setAutoLossIncrease(0);
  };

  const handleStopOnWin = async (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    stopOnWinValue = parseFloat(newStr);
    setStopOnWin(parseFloat(newStr));
    const response = await limboInstance().get(`/getUpdatedWallet`);
    const { wallet } = response?.data;
    if (response?.status === 200) {
      stopWinWallet = wallet ? wallet : 0.0;
    }
  };

  const handleStopOnLoss = async (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    stopOnLossValue = parseFloat(newStr);
    setStopOnLoss(parseFloat(newStr));
    const response = await limboInstance().get(`/getUpdatedWallet`);
    const { wallet } = response?.data;
    if (response?.status === 200) {
      stopLossWallet = wallet ? wallet : 0.0;
    }
  };

  const handleSound = () => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      localStorage.setItem("sound", false);
      setVolume(false);
    } else {
      localStorage.setItem("sound", true);
      setVolume(true);
    }
  };

  useEffect(() => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      setVolume(true);
    } else {
      setVolume(false);
    }
  }, []);

  const handleSelectKey = (k) => {
    setKey(k);
    setMultiplierError("");
  };
  /* Autobet code ends here */

  return (
    <div className="limbo">
      <div className="container">
        <div className="limbo-game-grid">
          <div className="limbo-tab-scroll">
            <div className="limbo-tab limbo-content">
              <div className="limbo-tab-popups"></div>
              <div className="limbo-tabs-data">
                <Tabs
                  id="uncontrolled-tab-example"
                  activeKey={key}
                  onSelect={(k) => handleSelectKey(k)}
                >
                  <Tab eventKey="manual" title="Manual" disabled={isDisabled}>
                    <ManualTab
                      handleBet={handleBet}
                      value={profitOnwin}
                      handleBetAmount={handleBetAmount}
                      betAmount={betAmount}
                      handleBetAmountUpdate={handleBetAmountUpdate}
                      testMode={testMode}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      errorMessage={errorMessage}
                    />
                  </Tab>
                  <Tab
                    eventKey="automated"
                    title="Automated"
                    disabled={isDisabled}
                  >
                    <AutomatedTab
                      handleAutobetClick={handleAutobetClick}
                      value={profitOnwin}
                      autoBetAmount={autoBetAmount}
                      handleAutoBetAmount={handleAutoBetAmount}
                      handleAutoBetAmountUpdate={handleAutoBetAmountUpdate}
                      handleNumberOfBets={handleNumberOfBets}
                      numberOfAutoBets={newNumberOfAutoBets}
                      handleAutoWinIncrease={handleAutoWinIncrease}
                      autoWinIncrease={autoWinIncrease}
                      handleAutoLossIncrease={handleAutoLossIncrease}
                      autoLossIncrease={autoLossIncrease}
                      resetWinIncrease={resetWinIncrease}
                      resetLossIncrease={resetLossIncrease}
                      stopOnWin={stopOnWin}
                      stopOnLoss={stopOnLoss}
                      handleStopOnWin={handleStopOnWin}
                      handleStopOnLoss={handleStopOnLoss}
                      testMode={testMode}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      activeAutoBets={activeAutoBets}
                      errorMessage={errorMessage}
                      errorBetMessage={""}
                      handleCancel={handleCancel}
                      isInfinite={isFiniteCase}
                    />
                  </Tab>
                </Tabs>
              </div>
              <div className="auto-bet auto-bet-mode">
                <p>Just for fun</p>
                <div className={`toggle-section`}>
                  <input
                    type="checkbox"
                    id="lb3"
                    checked={testMode}
                    onChange={handleTestMode}
                    disabled={isDisabled}
                  />
                  <Form.Label
                    htmlFor="lb3"
                    className={`${isDisabled ? "disabled-check" : ""}`}
                  />
                </div>
              </div>
            </div>
            <div className="limbo-game-footer">
              <div className="volume-btn">
                {volume ? (
                  <i
                    className="las la-volume-up"
                    role="presentation"
                    onClick={handleSound}
                  />
                ) : (
                  <i
                    className="las la-volume-off"
                    role="presentation"
                    onClick={handleSound}
                  />
                )}
              </div>
              {user && (
                <div className="limbo-game-btns">
                  <div className="fairness-btn" onClick={handlestatsShow}>
                    <img src={livestats} alt="livestats" />
                    <span>Live stats</span>
                  </div>
                  <div className="fairness-btn" onClick={handleShow}>
                    <img src={fairnessicon} alt="fairness" />
                    <span>Fairness</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="limbo-game">
            <div className="limbo-game-content">
              <div className="showHistory">{showHistory}</div>
              <div className={`limbo-payout ${scoreColor}`}>
                <CountUp
                  start={(result !== undefined ? result : 1.0) / 1.5}
                  duration={0.75}
                  end={result !== undefined ? result : 1.0}
                  decimals={2}
                />
                <span>x</span>
              </div>
              <div className="limbo-multiplier">
                <Form>
                  <Form.Group>
                    <Form.Label>Target Multiplier</Form.Label>
                    <Form.Control
                      type="number"
                      onBlur={handleMultiplier}
                      onChange={handelTargetMultiplier}
                      value={targetMuliplier}
                      placeholder="2.00"
                      min={1.01}
                      disabled={isFiniteCase || isDisabled}
                    />

                    {multiplierError ? (
                      <p className="errorMssgPopup">{multiplierError}</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Win Chance</Form.Label>
                    <div className="win-chance">
                      <Form.Control
                        type="number"
                        placeholder="96.00"
                        value={multiplier}
                        disabled={true}
                      />
                      <p>%</p>
                    </div>
                  </Form.Group>
                </Form>
              </div>
              <div className="limbo-history-rect-box">
                {pastPlayedGames &&
                  pastPlayedGames?.length > 0 &&
                  pastPlayedGames.map((ele) => {
                    return ele?.isCheck === "win" ? (
                      <div className="history-rect-box rect-active">
                        {ele?.result}
                      </div>
                    ) : (
                      <div className="history-rect-box">{ele?.result}</div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <audio className="audio-click" muted={!volume}>
          <source src={click}></source>
        </audio>
        <audio className="audio-counting" muted={!volume}>
          <source src={counting}></source>
        </audio>
        <audio className="audio-lose" muted={!volume}>
          <source src={lose}></source>
        </audio>
        <audio className="audio-win" muted={!volume}>
          <source src={win}></source>
        </audio>
      </div>
      <Modal
        show={fairness}
        onHide={handleClose}
        centered
        className="fairness-popup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Fairness</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FainessPopup
            seeds={allSeeds}
            finalResult={fairnessResult}
            fairnessCalculate={fairnessCalculate}
            handleSetNonce={handleSetNonce}
          />
        </Modal.Body>
      </Modal>
      {/* live stats */}
      <Modal
        show={stats}
        onHide={handlestatsClose}
        centered
        className="fairness-popup"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Live Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LiveStats statsData={statsData} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Limbo;
