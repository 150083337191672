// react-hooks/exhaustive-deps
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Limbo from "./components/limbo/limbo";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./responsive.css";
import "./components/limbo/limbo.css";
import { useEffect } from "react";
import { useAuthStore } from "./store/auth";

const App = () => {
  const { user, signIn } = useAuthStore((state) => state);
  useEffect(() => {
    if (!user) {
      signIn();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="App">
      <div className="main-wrapper">
        <React.Fragment>
          <Router>
            <main>
              <div className="main_content">
                <Switch>
                  <Route path="/" render={() => <Limbo />} />
                </Switch>
              </div>
            </main>
          </Router>
        </React.Fragment>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
