/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Form } from "react-bootstrap";
import doller from "../../assets/images/game/doller.svg";
import percentage from "../../assets/images/game/percentage.png";

const AutomatedTab = ({
  isDisabled,
  activeAutoBets,
  numberOfAutoBets,
  handleCancel,
  errorMessage,
  handleAutoBetAmountUpdate,
  handleAutoBetAmount,
  autoBetAmount,
  testMode,
  errorBetMessage,
  isInfinite,
  setIsDisabled,
  handleNumberOfBets,
  autoWinIncrease,
  handleAutoWinIncrease,
  resetWinIncrease,
  autoLossIncrease,
  handleAutoLossIncrease,
  resetLossIncrease,
  stopOnWin,
  handleStopOnWin,
  stopOnLoss,
  handleStopOnLoss,
  handleAutobetClick,
}) => {
  if (!activeAutoBets) {
    if (numberOfAutoBets === 0 || numberOfAutoBets === "0") {
      setIsDisabled(false);
    }
  }

  const handleKeyDown = (event) => {
    const disallowedChars = ["-", "+", "e", "E"];
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };
  let checkDisable = testMode ? testMode : isDisabled;

  return (
    <div className="manual-tab automated-tab">
      <Form>
        <div className="limbo-bet-box">
          <Form.Group>
            <Form.Label>Bet Amount</Form.Label>
            {errorMessage && <p className="errorMessage"> {errorMessage}</p>}
            <div className="bet-input pdd-200">
              <img src={doller} alt="doller" />
              <Form.Control
                type="text"
                onKeyDown={handleKeyDown}
                placeholder="0.00"
                disabled={checkDisable || isInfinite}
                value={autoBetAmount}
                onChange={handleAutoBetAmount}
              />
              <div className="bet-input-btn">
                <Button
                  onClick={() => handleAutoBetAmountUpdate("1/2")}
                  disabled={isDisabled || isInfinite}
                >
                  1/2
                </Button>
                <Button
                  onClick={() => handleAutoBetAmountUpdate("2x")}
                  disabled={isDisabled || isInfinite}
                >
                  2x
                </Button>
                <Button
                  onClick={() => handleAutoBetAmountUpdate("Max")}
                  disabled={isDisabled || isInfinite}
                >
                  Max
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Number of Bets</Form.Label>
            <div className="bet-inp">
              <Form.Control
                type="number"
                placeholder="0"
                value={numberOfAutoBets}
                onChange={handleNumberOfBets}
                disabled={isDisabled || isInfinite}
                min={0}
              />
              {numberOfAutoBets <= 0 && <i className="bi bi-infinity"></i>}
            </div>
            {errorBetMessage && (
              <p className="errorMessage"> {errorBetMessage}</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Win</Form.Label>
            <div className="bet-input pdd-200">
              <img src={percentage} alt="percentage" />
              <Form.Control
                type="number"
                placeholder="0"
                value={autoWinIncrease}
                onChange={handleAutoWinIncrease}
                disabled={isDisabled || isInfinite}
                min={0}
              />
              <div className="bet-input-btn">
                <Button>Increased by</Button>
                <Button
                  onClick={resetWinIncrease}
                  disabled={isDisabled || isInfinite}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Loss</Form.Label>
            <div className="bet-input pdd-200">
              <img src={percentage} alt="percentage" />
              <Form.Control
                type="number"
                placeholder="0"
                value={autoLossIncrease}
                onChange={handleAutoLossIncrease}
                disabled={isDisabled || isInfinite}
                min={0}
              />
              <div className="bet-input-btn">
                <Button>Increased by</Button>
                <Button
                  onClick={resetLossIncrease}
                  disabled={isDisabled || isInfinite}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form.Group>
          <div className="profit-content">
            <Form.Group className="stop-profit">
              <Form.Label>Stop on profit</Form.Label>
              <Form.Control
                type="number"
                placeholder="0.00"
                value={stopOnWin}
                onChange={handleStopOnWin}
                disabled={isDisabled || isInfinite}
                min={0}
              />
            </Form.Group>
            <Form.Group className="stop-profit">
              <Form.Label>Stop on loss</Form.Label>
              <Form.Control
                type="number"
                placeholder="0.00"
                value={stopOnLoss}
                onChange={handleStopOnLoss}
                disabled={isDisabled || isInfinite}
                min={0}
              />
            </Form.Group>
          </div>
        </div>

        <div className="roll-dice roll-dice-btn mt-2">
          {(isDisabled && parseInt(numberOfAutoBets) > 0) || isInfinite ? (
            <Button onClick={handleCancel}>
              <span>Cancel Autobet</span>
            </Button>
          ) : (
            <Button onClick={handleAutobetClick}>
              <span>Place Autobet</span>
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AutomatedTab;
