import React from "react";
import { Table } from "react-bootstrap";
import Chart from "react-apexcharts";
import moment from "moment";

const LiveStats = (statsData) => {

  let tableRow = [];
  let allProfits = [];
  let profitTotal = 0;
  let totalWagered = 0;
  let totalWin = 0;
  let totalLose = 0;
  let totalLossValue = 0;
  for (let index = 0; index < statsData?.statsData[0]?.length; index++) {
    let element = statsData?.statsData[0][index];

    totalWagered += element['amount'];
    if (element['profit']) {
      allProfits.push(element['profit']);
    }
    else {
      totalLose++;
      allProfits.push(0);
    }
    if (element["profit"] > 0) {
      profitTotal += element["profit"];
      let i = 1;
      totalWin += i;
      i++;
    }
    if (element["profit"] < 0) {
      let i = 1;
      totalLose += i;
     i++;
    }

    if (element['profit'] === 0 || element['profit'] === undefined) {
      totalLossValue += element['amount'];
    }

    tableRow.push(<tr><td>{element['gameType']}</td><td>{moment(element['createdAt']).format('YYYY-MM-DD HH:mm:ss')}</td><td>{element['amount']}</td><td>{element['multiplier']}</td>
      {element['profit']?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) === '$0.00' ? <td className='transaction-value loss-amt' >{element['profit']?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> : <td className='transaction-value'>{element['profit']?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>}
    </tr>)

  }

  const series = [
    {
      name: "Profit",
      data: allProfits,
    },
  ];
  const options = {
    chart: {
      fontFamily: "Montserrat",
      zoom: {
        enabled: false,
      },
      type: "area",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      area: {
        fillTo: "origin",
      },
    },
    colors: ["#fff"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.7,
    //     opacityTo: 0.9,
    //     stops: [0, 90, 100],
    //   },
    // },
    grid: {
      show: false,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    xaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          return value;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: (value) => {
          return value;
        },
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      fillSeriesColor: false,
      theme: ["#000"],
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        colors: ["#fff"],
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },

      marker: {
        show: true,
      },
      items: {
        display: "block",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 50,
      },
    },
  };
  const compareProfitValue = profitTotal - totalLossValue;
  return (
    <div className='live-stats-content'>
      <div className='live-stats-heading'>
        <div className='stats-top-status'>
          <p>Wagered</p> 
          <span>{totalWagered && totalWagered.toFixed(2)}</span> 
        </div>
        <div className='stats-top-status'>
          <p>Wins</p>
          <span>{totalWin && totalWin}</span>
        </div>
        <div className='stats-top-status'>
          <p>Losses</p>
          <span>{totalLose && totalLose}</span>
        </div>
        <div className='stats-top-status'>
          <p>Profit</p>
          {compareProfitValue > 0 ? <span>{compareProfitValue && compareProfitValue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span> : <span className='text-danger' >{compareProfitValue && compareProfitValue?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>}

        </div >
      </div >
      <Chart
        options={options}
        series={series}
        type='area'
        width='100%'
        height='300px'
        className='statsChart'
      />
      {/* <div className="live-stats-heading">
        <div className="stats-status">
          <p>Wins</p>
          <span>{totalWin && totalWin}</span>
        </div>
        <div className="stats-status">
          <p>Losses</p>
          <span>{totalLose && totalLose}</span>
        </div>
      </div> */}

      <div className='live-stats-table'>
        <div className='dice-history'>
          <Table striped bordered variant='dark' responsive>
            <thead>
              <tr>
                <th>Game</th>
                <th>Time</th>
                <th>Wagered </th>
                <th>Multiplier</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {tableRow}
              {/* <tr>
                <td>Limbo</td>
                <td>4:09 PM</td>
                <td></td>
                <td>0.00x</td>
                <td>
                  <div className='transaction-value'> $259.25</div>
                </td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="showing_num_records">Showing latest 20 records</div>
    </div >
  );
};

export default LiveStats;
