import React, { useState } from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import copy from "../../assets/images/game/copy-icon.png";
// import arrow from "../../assets/images/game/arrow-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const FainessPopup = (seeds) => {
  const [copied, setCopied] = useState("");
  return (
    <div className='fainess-popup-content'>
      <div className='limbo-tab limbo-tab2'>
        <Tabs defaultActiveKey='seeds' id='uncontrolled-tab-example'>
          <Tab eventKey='seeds' title='Seeds'>
            <Seeds seeds={seeds} setCopied={setCopied} copied={copied} />
          </Tab>
          <Tab eventKey='varify' title='Verify'>
            <Varify seeds={seeds} finalResult={seeds.finalResult} fairnessCalculate={seeds.fairnessCalculate} handleSetNonce={seeds.handleSetNonce} setCopied={setCopied} copied={copied}/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default FainessPopup;

const Seeds = ({seeds, setCopied, copied}) => {

  const handleCopy = (value) => {
    setCopied(value);
  };

  return (
    <div className='seeds-content'>
      <Form>
        <div className='tab-box'>
          <Form.Group>
            <Form.Label>Active Client Seed</Form.Label>
            <div className='limbo-input'>
              <Form.Control type='text' value={seeds?.seeds?.clientSeed}/>
              <div className='limbo-input-btn'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "clientSeed")
                  }
                >
                  <CopyToClipboard
                    text={seeds?.seeds?.clientSeed}
                    onCopy={() => handleCopy("clientSeed")}
                  >
                    <Button className='limbo-btn-copy'>
                      <img src={copy} alt='copy' />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Active Server Seed (Hashed)</Form.Label>
            <div className='limbo-input'>
              <Form.Control type='text' value={seeds?.seeds?.serverSeed}/>
              <div className='limbo-input-btn'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "serverSeed")
                  }
                >
                  <CopyToClipboard
                    text={seeds?.seeds?.serverSeed}
                    onCopy={() => handleCopy("serverSeed")}
                  >
                    <Button className='limbo-btn-copy'>
                      <img src={copy} alt='copy' />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Total bets made with pair</Form.Label>
            <div className='limbo-input'>
              <Form.Control type='number' value={seeds?.seeds?.nonce}/>
              <div className='limbo-input-btn'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderTooltip(props, copied, "nonce")}
                >
                  <CopyToClipboard
                    text={seeds?.seeds?.nonce}
                    onCopy={() => handleCopy("nonce")}
                  >
                    <Button className='limbo-btn-copy'>
                      <img src={copy} alt='copy' />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
    </div>
  );
};

const Varify = ({ seeds, setCopied, copied }) => {
  const handleCopy = (value) => {
    setCopied(value);
  };

  
  return (
    <div className='varify-content'>
      {/* <div className='varify-box'>
        <span>{seeds.finalResult}x</span>
      </div> */}

      <Form>
        <div className='tab-box'>
          <Form.Group>
            <div className='tab-box-game'>
              <Form.Label>Game</Form.Label>
              <select>
                <option>limbo</option>
                <option>crash</option>
                <option>Slot</option>
                <option>plinko</option>
              </select>
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Client Seed</Form.Label>
            <div className='limbo-input'>
              <Form.Control type='text' value={seeds?.seeds?.clientSeed}/>
              <div className='limbo-input-btn'>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "verifyClientSeed")
                  }
                >
                  <CopyToClipboard
                    text={seeds?.seeds?.clientSeed}
                    onCopy={() => handleCopy("verifyClientSeed")}
                  >
                    <Button className='limbo-btn-copy'>
                      <img src={copy} alt='copy' />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Server Seed</Form.Label>
            <div className='limbo-input'>
              <Form.Control type='text' />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className='limbo-input'>
              <Form.Control type='number' onChange={seeds.handleSetNonce} />
              {/* <div className='limbo-input-btn arrow-bottom'>
                <Button className='limbo-btn-copy'>
                  <img src={arrow} alt='arrow' />
                </Button>
              </div>
              <div className='limbo-input-btn arrow-top'>
                <Button className='limbo-btn-copy'>
                  <img src={arrow} alt='arrow' />
                </Button>
              </div> */}
            </div>
          </Form.Group>
        </div>
      </Form>
      <div className='varify-bottom-text' onClick={seeds.fairnessCalculate}>
        <span className='calcution-btn'>View Calculation Breakdown</span>
      </div>
      <div className='final_result'>Final result: {seeds.finalResult}</div>
    </div>
  );
};

const renderTooltip = (props, copied, type) => (
  <Tooltip id='button-tooltip' {...props} placement='top'>
    {copied === type ? "Copied" : "Copy"}
  </Tooltip>
);
