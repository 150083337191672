import axios from "axios";
import { server, landingServer } from "../config/keys";
import { getCookie } from "../utils/cookies";

export const userInstance = () => axios.create({
  baseURL: `${landingServer}/v1/users`,
  headers: { Authorization: `Bearer ${getCookie("token")}` },
});

export const gameInstance = axios.create({
  baseURL: `${server}/limbo_api/v1/games`,
  headers: {
    Authorization: `Bearer ${getCookie("token")}`,
  },
});

export const authInstance = axios.create({
  baseURL: `${server}/limbo_api/v1/auth`,
  headers: {
    Authorization: `Bearer ${getCookie("token")}`,
  },
});
export const limboInstance = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.create({
    baseURL: `${server}/limbo_api`,
    headers: {
      Authorization: `Bearer ${getCookie("token")}`,
    },
    timeout: 1000 * 20,
  });