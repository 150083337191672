import React from 'react';
import { Button, Form } from 'react-bootstrap';
import doller from '../../assets/images/game/doller.svg';

const ManualTab = ({ handleBet, value, handleBetAmount, betAmount, handleBetAmountUpdate, testMode, isDisabled, errorMessage }) => {
  const handleKeyDown = (event) => {
    const disallowedChars = ["-", "+", "e", "E"];
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <div className='manual-tab'>
      <Form>
        <div className='limbo-bet-box'>
          <Form.Group>
            <Form.Label>Bet Amount</Form.Label>
            {errorMessage && <p className="errorMessage">{`${errorMessage}`}</p>}
            <div className='bet-input pdd-200'>
              <img src={doller} alt='' />
              <Form.Control type='text' onKeyDown={handleKeyDown} placeholder='0.00' disabled={testMode ? testMode : isDisabled} value={betAmount} onChange={handleBetAmount} />
              <div className='bet-input-btn'>
                <Button onClick={() => handleBetAmountUpdate("1/2")} disabled={isDisabled}>1/2</Button>
                <Button onClick={() => handleBetAmountUpdate("2x")} disabled={isDisabled}>2x</Button>
                <Button onClick={() => handleBetAmountUpdate("Max")} disabled={isDisabled}>Max</Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Profit On Win</Form.Label>
            <Form.Control type='number' value={value} placeholder='1.00' disabled={true} min={0} />
          </Form.Group>

        </div>
        <div className='roll-dice roll-dice-btn mt-2'>
          <Button onClick={handleBet} disabled={isDisabled}><span>Place Bet</span></Button>
        </div>
      </Form>
    </div>
  );
};

export default ManualTab;
